import { gql } from "@apollo/client";
import { multiLangString } from "./common";
import { projectFragment } from "./newProject";
import { teamMemberFragment } from "./teamMembers";
import { employeeFragment } from "./newEmployee";
import {
  employeeReferenceFragment,
  newReferenceShortFragment,
  projectReferenceFragment,
} from "./references";
import { clientFields } from "./newClient";

export const COUNT_DRAFTS_AND_TRANSLATIONS = gql`
  query CountDraftsAndTranslationsQuery {
    countReadyForReviewDrafts
    countOutdatedTranslations
  }
`;

const draftsMetadataFragment = gql`
  fragment draftsMetadata_fields on VersionedEntity {
    modifiedBy {
      __typename
      ... on AutomatedChanger {
        id
      }
      ... on Employee {
        id
        fullName
      }
    }
    updatedAt
    insertedAt
    deletedAt
    isReadyForReview
  }
`;

export const DRAFTS = gql`
  ${draftsMetadataFragment}
  ${teamMemberFragment}
  ${projectFragment}
  ${employeeFragment}
  ${newReferenceShortFragment}
  ${clientFields}
  ${projectReferenceFragment}
  ${employeeReferenceFragment}

  query DraftsQuery {
    newProjectDrafts(includeUnlisted: true) {
      id
      ...Project_fields
      ...draftsMetadata_fields
      projectReferences {
        ...ProjectReference_fields
      }
      approved {
        id
        ...Project_fields
        projectReferences {
          ...ProjectReference_fields
        }
      }
    }
    employeeDrafts {
      id
      ...Employee_fields
      ...draftsMetadata_fields
      references {
        ...EmployeeReference_fields
      }
      approved {
        id
        ...Employee_fields
        references {
          ...EmployeeReference_fields
        }
      }
    }
    clientDrafts(includeUnlisted: true) {
      ...Client_fields
      ...draftsMetadata_fields
      approved {
        ...Client_fields
      }
    }
    referenceDrafts {
      id
      project {
        id
        name ${multiLangString}
        abacusProjectId
        archivedProjectId
        invoicingResponsible {
          id
          activeDirectoryId
          isFormerEmployee
        }
      }
      ...NewReference_shortFields
      employee {
        id
        fullName
      }
      ...draftsMetadata_fields
      approved {
        id
        project {
          id
          name ${multiLangString}
          abacusProjectId
          archivedProjectId
          invoicingResponsible {
            id
            activeDirectoryId
            isFormerEmployee
          }
        }
        ...NewReference_shortFields
        employee {
          id
          fullName
        }
      }
    }
    projectTeamMemberDrafts {
      ...teamMember_fields
      ...draftsMetadata_fields
      approved {
        ...teamMember_fields
      }
    }
  }
`;

export const APPROVE_PROJECT_DRAFT_MUTATION = gql`
  mutation ApplyNewProjectDraftMutation($id: ID!) {
    applyNewProjectDraft(id: $id) {
      id
      name ${multiLangString}
    }
  }
`;

export const REJECT_PROJECT_DRAFT_MUTATION = gql`
  mutation RejectNewProjectDraftMutation($id: ID!) {
    rejectNewProjectDraft(id: $id) {
      id
      name ${multiLangString}
    }
  }
`;

export const RESET_PROJECT_DRAFT_MUTATION = gql`
  mutation ResetNewProjectDraftMutation($id: ID!) {
    editNewProject(id: $id, fields: { isReadyForReview: false }) {
      id
      name ${multiLangString}
    }
  }
`;

export const APPROVE_EMPLOYEE_DRAFT_MUTATION = gql`
  ${employeeFragment}

  mutation ApplyEmployeeDraftMutation($id: ID!) {
    applyEmployeeDraft(id: $id) {
      id
      ...Employee_fields
    }
  }
`;

export const REJECT_EMPLOYEE_DRAFT_MUTATION = gql`
  ${employeeFragment}

  mutation RejectEmployeeDraftMutation($id: ID!) {
    rejectEmployeeDraft(id: $id) {
      id
      ...Employee_fields
    }
  }
`;

export const RESET_EMPLOYEE_DRAFT_MUTATION = gql`
  ${employeeFragment}

  mutation ResetEmployeeDraftMutation($id: ID!) {
    editEmployee(id: $id, fields: { isReadyForReview: false }) {
      id
      ...Employee_fields
    }
  }
`;

export const APPROVE_CLIENT_DRAFT_MUTATION = gql`
  mutation ApplyClientDraftMutation($id: ID!) {
    applyClientDraft(id: $id) {
      id
      name
    }
  }
`;

export const REJECT_CLIENT_DRAFT_MUTATION = gql`
  mutation RejectClientDraftMutation($id: ID!) {
    rejectClientDraft(id: $id) {
      id
      name
    }
  }
`;

export const RESET_CLIENT_DRAFT_MUTATION = gql`
  mutation ResetClientDraftMutation($id: ID!) {
    editClient(id: $id, fields: { isReadyForReview: false }) {
      id
      name
    }
  }
`;

export const APPROVE_REFERENCE_DRAFT_MUTATION = gql`
  mutation ApplyReferenceDraftMutation($id: ID!) {
    applyNewReferenceDraft(id: $id) {
      id
      description ${multiLangString}
    }
  }
`;

export const REJECT_REFERENCE_DRAFT_MUTATION = gql`
  mutation RejectReferenceDraftMutation($id: ID!) {
    rejectNewReferenceDraft(id: $id) {
      id
      description ${multiLangString}
    }
  }
`;

export const RESET_REFERENCE_DRAFT_MUTATION = gql`
  mutation ResetReferenceDraftMutation($id: ID!) {
    editNewReference(id: $id, fields: { isReadyForReview: false }) {
      id
      description ${multiLangString}
    }
  }
`;

export const APPROVE_TEAM_MEMBER_DRAFT_MUTATION = gql`
  ${teamMemberFragment}

  mutation ApplyProjectTeamMemberDraftMutation($id: ID!) {
    applyProjectTeamMemberDraft(id: $id) {
      ...teamMember_fields
    }
  }
`;

export const REJECT_TEAM_MEMBER_DRAFT_MUTATION = gql`
  ${teamMemberFragment}

  mutation RejectProjectTeamMemberDraftMutation($id: ID!) {
    rejectProjectTeamMemberDraft(id: $id) {
      ...teamMember_fields
    }
  }
`;

export const RESET_TEAM_MEMBER_DRAFT_MUTATION = gql`
  ${teamMemberFragment}

  mutation ResetProjectTeamMemberDraftMutation($id: ID!) {
    editProjectTeamMember(id: $id, fields: { isReadyForReview: false }) {
      ...teamMember_fields
    }
  }
`;
