import { useQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Layout from "layout/layout";
import useOnlineStatus from "helpers/useOnlineStatus";
import useInfiniteScroll from "helpers/useInfiniteScroll";
import PostExcerpt from "pages/post/show/excerpt";
import { POSTS_FEED_QUERY } from "queries/post";

import { ActionLinkRed, Button, FilterButton } from "components/button";
import { FaPlus } from "react-icons/fa";
import { Popup } from "components/popup";
import { LoadingSpinner } from "components/loadingSpinner";

// HELPER COMPONENTS

const tagCollections: { [k: string]: string[] } = {
  hr: ["Personelles", "HR", "Mitarbeiter"],
  project: ["Projektstory", "Projekt", "Akquisition"],
  news: ["Firmennews", "Intern", "Gesundheit"],
  digital: ["IT", "IT-FAQ", "TBFdigital", "Digitalisierung"],
  events: ["Event", "Workshop"],
  media_report: [
    "Medien",
    "Medienmitteilung",
    "Zeitung",
    "Fernsehen",
    "Zeitungsbericht",
    "Sendung",
  ],
  adz_full: ["Arbeitsplatz der Zukunft", "ADZ", "adz", "AdZ"],
};

const TagBar = ({ activeCollection, toggleCollectionActive }: any) => {
  const { t } = useTranslation("feed");

  return (
    <div className="sm:w-9/12 sm:mb-10">
      <div className="flex flex-row flex-wrap justify-start mb-8 sm:mb-4 gap-x-5 gap-y-2">
        {Object.entries(tagCollections).map(([key, _tags]) => {
          const isActive = activeCollection === key;
          const tags = tagCollections[key];

          return (
            <Popup
              key={key}
              content={
                tags ? `${t("storiesWithTags")}: ${tags.join(", ")}` : " "
              }
            >
              <FilterButton
                onClick={() => toggleCollectionActive(key)}
                active={isActive}
              >
                {t(`collections_${key}` as any)}
              </FilterButton>
            </Popup>
          );
        })}
      </div>
    </div>
  );
};

// MAIN COMPONENT

const Feed = () => {
  const { data, loading, fetchMore } = useQuery(POSTS_FEED_QUERY, {
    variables: { offset: 0, tags: [] as string[] },
    // Needed to set "loading" status correctly after "fetchMore"
    notifyOnNetworkStatusChange: true,
  });
  const isOnline = useOnlineStatus();
  const [hasMoreData, setHasMoreData] = useState(true);
  const [shouldFetchMore, setShouldFetchMore] = useState(false);
  const [activeCollection, setActiveCollection] = useState<string | undefined>(
    undefined,
  );

  const { t } = useTranslation(["common", "feed"]);

  const fetchFeed = (offset: number, tagList: string[], refetch = false) => {
    fetchMore({
      variables: {
        offset,
        tags: tagList,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        } else {
          return {
            posts: refetch
              ? fetchMoreResult.posts
              : [...prev.posts, ...fetchMoreResult.posts],
          };
        }
      },
    }).then((args: any) => {
      setShouldFetchMore(false);
      if (args?.data?.posts?.length === 0) {
        setHasMoreData(false);
      } else {
        resetInfiniteScroll();
      }

      if (refetch) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    });
  };

  const tags = (key?: string) => (key ? tagCollections[key] : []);

  const resetInfiniteScroll = useInfiniteScroll(() => {
    if (hasMoreData) {
      setShouldFetchMore(true);
    }
  });

  const toggleCollectionActive = (key: string) => {
    setHasMoreData(true);
    if (activeCollection === key) {
      setActiveCollection(undefined);
    } else {
      setActiveCollection(key);
    }
  };

  useEffect(() => {
    if (shouldFetchMore && !loading) {
      const offset = (data && data.posts && data.posts.length) || 0;
      fetchFeed(offset, tags(activeCollection), false);
    }
  }, [shouldFetchMore]);

  useEffect(() => {
    if (!loading) {
      fetchFeed(0, tags(activeCollection), true);
    }
  }, [activeCollection]);

  return (
    <>
      <Layout noPadding loading={loading} />
      <div className="relative px-3 mx-auto max-w-screen-tbf">
        <div data-cy="posts" className="max-w-2xl mx-auto relative bg-white">
          <div className="sticky pt-8 pb-4 bg-white top-12">
            <div className="flex flex-row justify-between mb-3">
              <div className="text-2xl font-medium text-red-500">
                {t("feed:currentPosts")}
              </div>
              <ActionLinkRed
                link={"/posts/new"}
                Icon={FaPlus}
                label={t("feed:shareStory")}
              />
            </div>
            <TagBar
              activeCollection={activeCollection}
              toggleCollectionActive={toggleCollectionActive}
              isOnline={isOnline}
            />
          </div>
          {data?.posts?.map((post: any) => (
            <PostExcerpt key={post.id} post={post} />
          ))}
          <div className="flex flex-col items-center">
            {loading ? <LoadingSpinner /> : null}
            {!hasMoreData ? <div>{t("feed:noMoreStories")}</div> : null}
          </div>
          {hasMoreData && !loading ? (
            <div className="flex flex-col items-center pt-6 pb-4">
              <Button
                onClick={() => {
                  resetInfiniteScroll();
                  if (shouldFetchMore && !loading) {
                    const offset =
                      (data && data.posts && data.posts.length) || 0;
                    fetchFeed(offset, tags(activeCollection), false);
                  }
                }}
              >
                {t("common:more")}
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Feed;
