import Appsignal from "@appsignal/javascript";
import * as AppsignalPathPlugin from "@appsignal/plugin-path-decorator";
import * as AppsignalWindowEventsPlugin from "@appsignal/plugin-window-events";

import { stages, getReleaseStage } from "helpers/getReleaseStage";

export const appsignal = new Appsignal({
  key:
    getReleaseStage() === stages.production_k8
      ? "8b4749c2-29be-420a-bfbf-b97a1eb1097d"
      : getReleaseStage() === stages.staging_k8
        ? "eb2eb8de-aca4-4ceb-a604-23bd77ba01a9"
        : // dev
          "ba893198-58a9-403c-b4c4-bd02c7cc2f8c",
  // @ts-ignore
  revision: import.meta.env.VITE_APP_REVISION,
  // We can safely ignore ResizeObserver loop error
  // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
  ignoreErrors: [/ResizeObserver loop limit exceeded/],
});
appsignal.use(AppsignalPathPlugin.plugin());
appsignal.use(AppsignalWindowEventsPlugin.plugin());
