import React from "react";
import { useQuery } from "@apollo/client";
import Layout from "layout/layout";
import { ManageProjects } from "./manageProjects";
import { ManageClients } from "./manageClients";
import { ManageEmployees } from "./manageEmployees";
import { COUNT_DRAFTS_AND_TRANSLATIONS } from "queries/newDraft";
import DraftShell from "./drafts";
import { OutdatedTranslations } from "./outdatedTranslations";

import { TabBar, TabContent } from "components/tab";
import ReferenceData from "pages/newReferenceData";

const ManageDataShell = ({ tab }: { tab?: string }): JSX.Element => {
  const { data } = useQuery(COUNT_DRAFTS_AND_TRANSLATIONS);

  const panes = [
    {
      name: "Projekte",
      key: "projects",
      Content: ManageProjects,
    },
    {
      name: "Kunden",
      key: "clients",
      Content: ManageClients,
    },
    {
      name: "Mitarbeiter",
      key: "employees",
      Content: ManageEmployees,
    },
    {
      name: "Kennsätze",
      key: "reference-data",
      Content: () => {
        return <ReferenceData />;
      },
    },
    {
      name: "Änderungsanträge",
      annotation:
        (data?.countReadyForReviewDrafts ?? 0 > 0)
          ? data?.countReadyForReviewDrafts
          : null,
      key: "drafts",
      Content: DraftShell,
    },
    {
      name: "Zu Übersetzen",
      annotation:
        (data?.countOutdatedTranslations ?? 0 > 0)
          ? data?.countOutdatedTranslations
          : null,
      key: "translations",
      Content: OutdatedTranslations,
    },
  ];

  const tabProps = { panes, path: "/manage-data", currentTab: tab };

  return (
    <>
      <Layout loading={false} noPadding />
      <div className="flex flex-col" style={{ height: "calc(100vh - 58px)" }}>
        <div className="z-30 bg-white border-b shadow">
          <div className="max-w-screen-xl px-3 mx-auto">
            <h1 className="py-8 text-2xl font-medium text-red-500">
              Daten verwalten
            </h1>

            <div className="max-w-screen-xl pb-3 mx-auto">
              <TabBar {...tabProps} />
            </div>
          </div>
        </div>
        <TabContent {...tabProps} />
      </div>
    </>
  );
};

export default ManageDataShell;
