import React from "react";
import { useTranslation } from "react-i18next";
import Excerpt from "pages/post/show/excerpt";

export const Stories = ({ posts = [] }: any): JSX.Element => {
  const { t } = useTranslation("employee");

  return (
    <div>
      <div className="my-6">
        <div className="text-2xl mt-2 text-red-500">{t("myStories")}</div>
      </div>
      <div className="compact">
        {posts.map((post: any) => (
          <div key={post.id} className="">
            <Excerpt post={post} />
          </div>
        ))}
      </div>
    </div>
  );
};
