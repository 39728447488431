import React, { useCallback, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "wouter";
import { DocumentNode } from "graphql";
import { useMutation } from "@apollo/client";
import {
  FaEdit,
  FaCheck,
  FaSpinner,
  FaTrashAlt,
  FaExternalLinkAlt,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";
import { FaArrowRotateLeft } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import debounce from "debounce";
import {
  filterDrafts,
  newestFirst,
  byGroupId,
  draftGroups,
  GroupableEntity,
  DraftGroup,
} from "./utils";

import { Modal } from "components/modal";
import { IconButton, TabButton } from "components/button";
import { clientFormDefinition } from "components/editVersioned/editClient";
import { employeeFormDefinition } from "components/editVersioned/editEmployee";
import { projectFormDefinition } from "components/editVersioned/editProject";
import { referenceFormDefinition } from "components/editVersioned/editReference";
import { teamMemberFormDefinition } from "components/editVersioned/editTeamMember";
import {
  SavingIndicator,
  stripUnknownValuesBeforeSave,
  toMultiLangField,
  VersionedEntity,
} from "components/editVersioned/genericForm";
import { FormDefinition, FieldName } from "components/form";
import { LanguageSelector } from "components/languageSelector";
import { languages } from "helpers/languages";
import { Employee, EntityChanger, Maybe } from "generated/graphql";
import { EDIT_NEW_PROJECT_MUTATION } from "queries/newProject";
import { EDIT_CLIENT_MUTATION } from "queries/newClient";
import { EDIT_EMPLOYEE_MUTATION } from "queries/newEmployee";
import { EDIT_REFERENCE_MUTATION } from "queries/references";
import { EDIT_TEAM_MEMBER_MUTATION } from "queries/teamMembers";
import { groupBy, pick } from "ramda";
import dayjs from "dayjs";
import { useMultiLang } from "helpers/multiLang";
import AuthContext from "context/user";

// HELPER FUNCTIONS
const ConfirmationModal = ({
  showConfirmation,
  setShowConfirmation,
  confirmationText,
  type,
  confirmButtonText,
  applyActionCallback,
}) => {
  const { t } = useTranslation("common");

  const iconType =
    type == "reject"
      ? FaTrashAlt
      : type == "approve"
        ? FaCheck
        : FaArrowRotateLeft;

  const buttonType = type == "reject" ? "warning" : "normal";

  return (
    <Modal
      size="small"
      title={
        <div className="px-6 py-8 text-2xl font-medium text-red-500">
          {t("confirm")}
        </div>
      }
      open={showConfirmation}
      close={() => setShowConfirmation(false)}
      actions={
        <div className="flex flex-row space-x-2">
          <IconButton
            type="secondary"
            Icon={IoMdClose}
            onClick={() => setShowConfirmation(false)}
          >
            {t("cancel")}
          </IconButton>
          <IconButton
            Icon={iconType}
            type={buttonType}
            onClick={() => {
              applyActionCallback();
              setShowConfirmation(false);
            }}
          >
            {confirmButtonText}
          </IconButton>
        </div>
      }
    >
      <div className="px-4">
        <div className="px-6 my-8">{confirmationText}</div>
      </div>
    </Modal>
  );
};

const Changer = ({
  changer,
}: {
  changer: Maybe<EntityChanger> | undefined;
}) => {
  if (changer?.__typename === "AutomatedChanger") {
    return <>LDAP / Abacus</>;
  } else if (changer?.__typename === "Employee") {
    const employee = changer as Employee;
    return <Link href={`/employee/${employee.id}`}>{employee.fullName}</Link>;
  }
  return <>Unbekannt</>;
};

const getChangeType = (draft: Partial<VersionedEntity>) => {
  return draft.approved === null
    ? "new"
    : draft.deletedAt
      ? "removed"
      : "edited";
};

const GenericDraft = <T extends VersionedEntity>({
  formDefinition,
  editMutation,
  approved,
  draft,
  language,
  approveDraft,
  rejectDraft,
  resetDraft,
}: {
  formDefinition: FormDefinition<T>;
  editMutation: DocumentNode;
  draft: Partial<T>;
  approved?: Partial<T> | null;
  language: string;
  approveDraft: (id: string) => any;
  rejectDraft: (id: string) => any;
  resetDraft: (id: string) => any;
}) => {
  const [editMut] = useMutation<T>(editMutation);

  const [saving, setSaving] = useState(false);
  const [performingApplyAction, setPerformingApplyAction] = useState(false);
  const [performingRejectAction, setPerformingRejectAction] = useState(false);
  const [performingResetAction, setPerformingResetAction] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const debouncedSave = useCallback(
    debounce(async (newValues) => {
      const result = await editMut({
        variables: {
          id: draft.id,
          fields: stripUnknownValuesBeforeSave({
            values: newValues,
            formDefinition,
          }),
        },
      }).catch((err) => {
        setError(JSON.stringify(err));
        setSaving(true);
      });
      setSaving(false);
      return result;
    }, 500),
    [draft.id],
  );

  const save = (newValues: Partial<T>) => {
    setSaving(true);
    debouncedSave(newValues);
  };

  const { t } = useTranslation("drafts");
  const [values, setValues] = useState(draft);
  const [showUnchanged, setShowUnchanged] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const changeType = getChangeType(draft);

  return (
    <div className="relative">
      {/* This overlay prevents any interaction while performing an action */}
      {performingApplyAction || performingRejectAction ? (
        <div className="absolute top-0 left-0 z-10 w-full h-full bg-gray-500 opacity-0" />
      ) : null}
      <div className="flex items-end justify-between mt-8">
        <div className="flex-1 pb-2">
          {formDefinition.title({ entity: approved || draft, t })}
        </div>
        <div className="flex items-center justify-end mb-2 space-x-2 text-xs text-gray-600">
          <FaEdit />
          <div>
            {changeType === "new" && "Eintrag erstellt"}
            {changeType === "removed" && "Eintrag gelöscht"}
            {changeType === "edited" && "Eintrag geändert"} von{" "}
            <Changer changer={draft.modifiedBy} /> am{" "}
            {dayjs(`${draft.updatedAt}Z`).format("DD.MM.YYYY")}
          </div>
          <div className="flex items-center justify-end pl-2 space-x-2 border-l border-gray-200">
            {approved ? (
              <label>
                <input
                  className="mr-1 relative inline-block top-0.5"
                  type="checkbox"
                  checked={showUnchanged}
                  onChange={(e) => {
                    setShowUnchanged(e.currentTarget.checked);
                  }}
                />
                Unveränderte Werte anzeigen
              </label>
            ) : null}
          </div>
        </div>
      </div>

      <div className="-mx-4">
        <div className="text-sm">
          <div className="grid font-medium bg-gray-100 border-t border-b border-gray-200 grid-cols-drafts">
            <div className="p-2 pl-4">Feld</div>
            <div className="p-2 pl-3">Aktueller Wert</div>
            <div className="p-2 pl-3">Neuer Wert</div>
          </div>

          <div className="">
            {Object.entries(formDefinition.fields).map(([key, field]) => {
              if (!field) {
                return null;
              }
              if (field.displayIf && !field.displayIf(values)) {
                return null;
              }

              const isMultiLangField = !!field.multiLang;
              const Field = isMultiLangField
                ? useCallback(toMultiLangField(field.component.renderer), [
                    field,
                  ])
                : field.component.renderer;

              const fieldEquals = field.component.equals || ((a, b) => a === b);
              const equals = isMultiLangField
                ? (a, b) => {
                    return languages.reduce<boolean>(
                      (acc, lang) => acc && fieldEquals(a?.[lang], b?.[lang]),
                      true,
                    );
                  }
                : fieldEquals;

              const notModified =
                !showUnchanged &&
                changeType === "edited" &&
                (field.controlledFields
                  ? equals(approved, draft)
                  : equals(approved?.[key], draft[key]));
              if (notModified) {
                return null;
              }

              const updateValue = (v) => {
                let newValues;
                if (field.controlledFields) {
                  newValues = {
                    ...values,
                    ...v,
                  };
                } else {
                  newValues = {
                    ...values,
                    [key]: v,
                  };
                }
                setValues(newValues);
                save(newValues);
              };

              const approvedValue = field.controlledFields
                ? pick(field.controlledFields, approved ?? {})
                : approved?.[key];
              const value = field.controlledFields
                ? pick(field.controlledFields, values)
                : values?.[key];

              return (
                <div
                  key={`${key}-${language}`}
                  className="grid grid-cols-drafts even:bg-gray-100"
                >
                  <div className="py-2 pl-4 ">
                    <FieldName field={field} values={values} fieldKey={key} />
                  </div>
                  <div className="p-2">
                    {approved && (
                      <Field
                        value={approvedValue}
                        language={language}
                        disabled={true}
                        entity={approved}
                        {...field.props}
                      />
                    )}
                  </div>
                  <div className="p-2 pr-4">
                    {changeType === "removed" ? (
                      "Der Eintrag wurde gelöscht."
                    ) : (
                      <Field
                        value={value}
                        onChange={updateValue}
                        language={language}
                        entity={values}
                        {...field.props}
                      />
                    )}
                  </div>
                </div>
              );
            })}

            <div className="p-4 text-right border-t border-gray-200">
              {error ? <div className="bg-red-300">{error}</div> : null}
              <div className="flex items-center justify-between">
                <SavingIndicator saving={saving} />
                <div className="flex items-center space-x-2">
                  <IconButton
                    type="warning"
                    Icon={
                      performingRejectAction
                        ? () => <FaSpinner className="animate-spin" />
                        : FaTrashAlt
                    }
                    onClick={() => {
                      setShowConfirmation(true);
                    }}
                  >
                    {t("reject")}
                  </IconButton>
                  {showConfirmation ? (
                    <ConfirmationModal
                      showConfirmation={showConfirmation}
                      setShowConfirmation={setShowConfirmation}
                      confirmationText={t("confirmReject")}
                      type="reject"
                      confirmButtonText={t("reject")}
                      applyActionCallback={() => {
                        if (draft.id) {
                          setPerformingRejectAction(true);
                          rejectDraft(draft.id).catch(() => {
                            setPerformingRejectAction(false);
                          });
                          // No need to reset `performingRejectAction` to true:
                          // After the mutation all drafts are refetched and this draft is removed from the dom
                          // Any attempt to modify the component after the mutation will result in an error
                          // since the component do not exists anymore
                        }
                      }}
                    />
                  ) : null}
                  <IconButton
                    type="secondary"
                    Icon={
                      performingResetAction
                        ? () => <FaSpinner className="animate-spin" />
                        : FaArrowRotateLeft
                    }
                    onClick={() => {
                      if (draft.id) {
                        setPerformingResetAction(true);
                        resetDraft(draft.id).catch(() => {
                          setPerformingResetAction(false);
                        });
                        // No need to reset `performingResetAction` to true:
                        // After the mutation all drafts are refetched and this draft is removed from the dom
                        // Any attempt to modify the component after the mutation will result in an error
                        // since the component do not exists anymore
                      }
                    }}
                  >
                    {t("backToDraft")}
                  </IconButton>
                  <IconButton
                    Icon={
                      performingApplyAction
                        ? () => <FaSpinner className="animate-spin" />
                        : FaCheck
                    }
                    onClick={() => {
                      if (draft.id) {
                        setPerformingApplyAction(true);
                        approveDraft(draft.id).catch(() => {
                          setPerformingApplyAction(false);
                        });
                        // No need to reset `performingRejectAction` to true:
                        // After the mutation all drafts are refetched and this draft is removed from the dom
                        // Any attempt to modify the component after the mutation will result in an error
                        // since the component do not exists anymore
                      }
                    }}
                  >
                    {t("approve")}
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const draftTypeMapping = {
  NewProject: {
    formDefinition: projectFormDefinition,
    editMutation: EDIT_NEW_PROJECT_MUTATION,
  },
  Client: {
    formDefinition: clientFormDefinition,
    editMutation: EDIT_CLIENT_MUTATION,
  },
  Employee: {
    formDefinition: employeeFormDefinition,
    editMutation: EDIT_EMPLOYEE_MUTATION,
  },
  NewReference: {
    formDefinition: referenceFormDefinition,
    editMutation: EDIT_REFERENCE_MUTATION,
  },
  ProjectTeamMember: {
    formDefinition: teamMemberFormDefinition,
    editMutation: EDIT_TEAM_MEMBER_MUTATION,
  },
};

const DraftsGroup = ({
  drafts,
  approveActionMapping,
  rejectActionMapping,
  resetActionMapping,
}: DraftGroup) => {
  const firstDraft = drafts[0];
  const firstDraftType = draftTypeMapping[firstDraft.data.__typename!];
  const [language, setLanguage] = useState<string>(languages[0]);
  const [expanded, setExpanded] = useState(false);
  const [performingApplyAction, setPerformingApplyAction] = useState(false);
  const [performingRejectAction, setPerformingRejectAction] = useState(false);
  const [performingResetAction, setPerformingResetAction] = useState(false);
  const { t } = useTranslation("drafts");
  const m = useMultiLang();

  const [showRejectConfirmation, setShowRejectConfirmation] = useState(false);
  const [showResetConfirmation, setShowResetConfirmation] = useState(false);
  const [showApproveConfirmation, setShowApproveConfirmation] = useState(false);

  const entity = firstDraft.data.approved || firstDraft.data;
  const draftGroup = draftGroups[firstDraft.groupType];
  const title = draftGroup.title(t, m, entity, firstDraftType.formDefinition);
  const link = (draftGroup.link || draftGroups.single.link)(
    t,
    entity,
    firstDraftType.formDefinition,
  );

  const draftsByChangeType = groupBy(
    (entity) => getChangeType(entity.data),
    drafts,
  );

  const summary = Object.entries(draftsByChangeType)
    .map(([changeType, changes]) => {
      const draftsByTypeName = groupBy((d) => d.data.__typename || "", changes);
      return Object.entries(draftsByTypeName)
        .map(([typeName, changes]) => {
          const count = changes.length;
          return (
            t(`groupSubTitle.${typeName}` as any, { count }) +
            " " +
            t(`groupSubTitle.${changeType}` as any)
          );
        })
        .join(", ");
    })
    .join(", ");

  return (
    <div className="max-w-screen-xl px-3 mx-auto">
      <div className="relative px-4 pt-4 mb-4 border border-gray-200 rounded-md shadow-md">
        {/* This overlay prevents any interaction while performing an action */}
        {performingApplyAction || performingRejectAction ? (
          <div className="absolute top-0 left-0 z-10 w-full h-full bg-gray-500 opacity-0" />
        ) : null}
        <div className="flex items-start justify-between">
          <Link href={link} target="_blank" className="text-lg font-medium">
            {title}
          </Link>
          <div className="flex items-center space-x-6">
            <a
              className="flex items-center px-2 py-1 text-sm text-blue-500 whitespace-pre border rounded border-grey-300 hover:bg-opacity-5 hover:border-opacity-40 focus:outline-none hover:bg-blue-500 hover:border-blue-500"
              target="_blank"
              href={link}
              rel="noreferrer"
            >
              <FaExternalLinkAlt aria-hidden="true" />
              <span className="ml-2">Zum Eintrag</span>
            </a>
            <LanguageSelector
              currentLanguage={language}
              setCurrentLanguage={setLanguage}
            />
            <IconButton
              Icon={expanded ? FaChevronUp : FaChevronDown}
              onClick={() => setExpanded(!expanded)}
              className="flex items-center justify-center h-7 w-7"
            />
          </div>
        </div>
        {summary}
        {expanded &&
          drafts?.sort(newestFirst)?.map((currentDraft) => {
            const typeName = currentDraft.data.__typename!;
            const draftType = draftTypeMapping[typeName];
            return (
              <div key={currentDraft.data.id}>
                <GenericDraft
                  formDefinition={
                    draftType.formDefinition as FormDefinition<VersionedEntity>
                  }
                  editMutation={draftType.editMutation}
                  draft={currentDraft.data}
                  approved={currentDraft.data.approved}
                  approveDraft={approveActionMapping[typeName]}
                  rejectDraft={rejectActionMapping[typeName]}
                  resetDraft={resetActionMapping[typeName]}
                  language={language}
                />
              </div>
            );
          })}
        {(drafts.length > 1 || !expanded) && (
          <div
            className={`p-4 -mx-4 text-right ${
              expanded ? "bg-gray-100 border-t" : ""
            } border-gray-200`}
          >
            <div className="flex items-center justify-end">
              <div className="flex items-center space-x-2">
                <IconButton
                  type="warning"
                  className="bg-white"
                  Icon={
                    performingRejectAction
                      ? () => <FaSpinner className="animate-spin" />
                      : FaTrashAlt
                  }
                  onClick={() => {
                    setShowRejectConfirmation(true);
                  }}
                >
                  {t("rejectAll")}
                </IconButton>
                {showRejectConfirmation ? (
                  <ConfirmationModal
                    showConfirmation={showRejectConfirmation}
                    setShowConfirmation={setShowRejectConfirmation}
                    confirmationText={t("confirmRejectAll")}
                    type="reject"
                    confirmButtonText={t("rejectAll")}
                    applyActionCallback={() => {
                      setPerformingRejectAction(true);
                      Promise.all(
                        drafts.map((d) => {
                          const rejectDraft =
                            rejectActionMapping[d.data.__typename!];
                          return rejectDraft(d.data.id);
                        }),
                      ).catch(() => {
                        setPerformingRejectAction(false);
                      });
                      // No need to reset `performingRejectAction` to true:
                      // After the mutation all drafts are refetched and this draft is removed from the dom
                      // Any attempt to modify the component after the mutation will result in an error
                      // since the component do not exists anymore
                    }}
                  />
                ) : null}
                <IconButton
                  type="secondary"
                  className="bg-white"
                  Icon={
                    performingResetAction
                      ? () => <FaSpinner className="animate-spin" />
                      : FaArrowRotateLeft
                  }
                  onClick={() => {
                    setShowResetConfirmation(true);
                  }}
                >
                  {t("backToDraft")}
                </IconButton>
                {showResetConfirmation ? (
                  <ConfirmationModal
                    showConfirmation={showResetConfirmation}
                    setShowConfirmation={setShowResetConfirmation}
                    confirmationText={t("confirmResetAll")}
                    type="reset"
                    confirmButtonText={t("backToDraft")}
                    applyActionCallback={() => {
                      setPerformingResetAction(true);
                      Promise.all(
                        drafts.map((d) => {
                          const resetDraft =
                            resetActionMapping[d.data.__typename!];
                          return resetDraft(d.data.id);
                        }),
                      ).catch(() => {
                        setPerformingResetAction(false);
                      });
                      // No need to reset `performingRejectAction` to true:
                      // After the mutation all drafts are refetched and this draft is removed from the dom
                      // Any attempt to modify the component after the mutation will result in an error
                      // since the component do not exists anymore
                    }}
                  />
                ) : null}
                <IconButton
                  className="bg-white"
                  Icon={
                    performingApplyAction
                      ? () => <FaSpinner className="animate-spin" />
                      : FaCheck
                  }
                  onClick={() => {
                    setShowApproveConfirmation(true);
                  }}
                >
                  {t("approveAll")}
                </IconButton>
                {showApproveConfirmation ? (
                  <ConfirmationModal
                    showConfirmation={showApproveConfirmation}
                    setShowConfirmation={setShowApproveConfirmation}
                    confirmationText={t("confirmApproveAll")}
                    type="approve"
                    confirmButtonText={t("approveAll")}
                    applyActionCallback={() => {
                      setPerformingApplyAction(true);
                      Promise.all(
                        drafts.map((d) => {
                          const approveDraft =
                            approveActionMapping[d.data.__typename!];
                          return approveDraft(d.data.id);
                        }),
                      ).catch(() => {
                        setPerformingApplyAction(false);
                      });
                      // No need to reset `performingRejectAction` to true:
                      // After the mutation all drafts are refetched and this draft is removed from the dom
                      // Any attempt to modify the component after the mutation will result in an error
                      // since the component do not exists anymore
                    }}
                  />
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

type DraftsType = {
  loading: boolean;
  projectDrafts: any;
  clientDrafts: any;
  employeeDrafts: any;
  referenceDrafts: any;
  projectTeamMemberDrafts: any;
  approveProjectDraft: (id: string) => any;
  rejectProjectDraft: (id: string) => any;
  resetProjectDraft: (id: string) => any;
  approveClientDraft: (id: string) => any;
  rejectClientDraft: (id: string) => any;
  resetClientDraft: (id: string) => any;
  approveEmployeeDraft: (id: string) => any;
  rejectEmployeeDraft: (id: string) => any;
  resetEmployeeDraft: (id: string) => any;
  approveReferenceDraft: (id: string) => any;
  rejectReferenceDraft: (id: string) => any;
  resetReferenceDraft: (id: string) => any;
  approveTeamMemberDraft: (id: string) => any;
  rejectTeamMemberDraft: (id: string) => any;
  resetTeamMemberDraft: (id: string) => any;
};

const Drafts = ({
  loading,
  projectDrafts,
  clientDrafts,
  employeeDrafts,
  referenceDrafts,
  projectTeamMemberDrafts,
  approveProjectDraft,
  rejectProjectDraft,
  resetProjectDraft,
  approveClientDraft,
  rejectClientDraft,
  resetClientDraft,
  approveEmployeeDraft,
  rejectEmployeeDraft,
  resetEmployeeDraft,
  approveReferenceDraft,
  rejectReferenceDraft,
  resetReferenceDraft,
  approveTeamMemberDraft,
  rejectTeamMemberDraft,
  resetTeamMemberDraft,
}: DraftsType) => {
  const { t } = useTranslation("drafts");
  const { user } = useContext<any>(AuthContext);

  const approveActionMapping = {
    NewProject: approveProjectDraft,
    Client: approveClientDraft,
    Employee: approveEmployeeDraft,
    NewReference: approveReferenceDraft,
    ProjectTeamMember: approveTeamMemberDraft,
  };

  const rejectActionMapping = {
    NewProject: rejectProjectDraft,
    Client: rejectClientDraft,
    Employee: rejectEmployeeDraft,
    NewReference: rejectReferenceDraft,
    ProjectTeamMember: rejectTeamMemberDraft,
  };

  const resetActionMapping = {
    NewProject: resetProjectDraft,
    Client: resetClientDraft,
    Employee: resetEmployeeDraft,
    NewReference: resetReferenceDraft,
    ProjectTeamMember: resetTeamMemberDraft,
  };

  const allTypesDrafts = {
    projectDrafts,
    clientDrafts,
    employeeDrafts,
    referenceDrafts,
    projectTeamMemberDrafts,
  };

  const allProjectsDrafts = filterDrafts(allTypesDrafts, "allProjects");
  const allUserProjectsDrafts = filterDrafts(
    allTypesDrafts,
    "myProjects",
    user.username,
  );
  const allUnassignedProjectsDrafts = filterDrafts(
    allTypesDrafts,
    "unassignedProjects",
  );
  const allClientsDrafts = filterDrafts(allTypesDrafts, "clients");
  const allemployeeDrafts = filterDrafts(allTypesDrafts, "employees");
  const automaticChangesDrafts = filterDrafts(
    allTypesDrafts,
    "automaticChanges",
  );
  const allStillEditingDrafts = filterDrafts(allTypesDrafts, "stillEditing");

  const categories = [
    {
      label: t("categories.allProjects"),
      key: "allProjects",
      drafts: allProjectsDrafts.sort(newestFirst),
      annotation:
        allProjectsDrafts.length > 0 ? allProjectsDrafts.length : null,
    },
    {
      label: t("categories.myProjects"),
      key: "myProjects",
      drafts: allUserProjectsDrafts.sort(newestFirst),
      annotation:
        allUserProjectsDrafts.length > 0 ? allUserProjectsDrafts.length : null,
    },
    {
      label: t("categories.unassignedProjects"),
      key: "unassignedProjects",
      drafts: allUnassignedProjectsDrafts.sort(newestFirst),
      annotation:
        allUnassignedProjectsDrafts.length > 0
          ? allUnassignedProjectsDrafts.length
          : null,
    },
    {
      label: t("categories.employees"),
      key: "employees",
      drafts: allemployeeDrafts.sort(newestFirst),
      annotation:
        allemployeeDrafts.length > 0 ? allemployeeDrafts.length : null,
    },
    {
      label: t("categories.clients"),
      key: "clients",
      drafts: allClientsDrafts.sort(newestFirst),
      annotation: allClientsDrafts.length > 0 ? allClientsDrafts.length : null,
    },
    {
      label: t("categories.stillEditing"),
      key: "stillEditing",
      drafts: allStillEditingDrafts.sort(newestFirst),
      annotation:
        allStillEditingDrafts.length > 0 ? allStillEditingDrafts.length : null,
      grayAnnotation: true,
    },
    {
      label: t("categories.automatic"),
      key: "automatic",
      drafts: automaticChangesDrafts.sort(newestFirst),
      annotation:
        automaticChangesDrafts.length > 0
          ? automaticChangesDrafts.length
          : null,
    },
  ];

  const [activeCategory, setActiveCategory] = useState("allProjects");
  const currentDrafts =
    categories.find((c) => c.key === activeCategory)?.drafts ?? [];

  const mostRecentDate = (drafts: GroupableEntity[]) => {
    return drafts
      .map((e) => e.data.insertedAt ?? "")
      .sort()
      .reverse()[0];
  };

  const [showApproveConfirmation, setShowApproveConfirmation] = useState(false);
  const [showRejectConfirmation, setShowRejectConfirmation] = useState(false);

  const groupedDrafts: Array<[string, GroupableEntity[] | undefined]> =
    Object.entries(byGroupId(currentDrafts)).sort(([_keyA, a], [_keyB, b]) => {
      return mostRecentDate(a!) > mostRecentDate(b!) ? -1 : 1;
    });

  return loading ? null : (
    <div className="flex flex-col h-full">
      <div className="z-20 pt-4 pb-2 bg-white border-b border-gray-300 shadow">
        <div className="flex items-center max-w-screen-xl px-3 mx-auto space-x-4">
          {categories.map((cat) => (
            <TabButton
              onClick={() => {
                setActiveCategory(cat.key);
              }}
              key={cat.key}
              primary={activeCategory === cat.key}
            >
              {cat.label}
              {cat.annotation && (
                <span
                  className={`mb-2 ml-1 text-xs ${
                    cat.grayAnnotation ? "text-gray-500" : "text-red-500"
                  }`}
                >
                  {cat.annotation}
                </span>
              )}
            </TabButton>
          ))}
        </div>
      </div>
      {activeCategory === "stillEditing" && (
        <div className="max-w-screen-xl p-4 pt-4 mx-auto mt-4 text-center text-red-500">
          <div className="flex mr-4 text-4xl" />
          {t("isStillEditing")}
        </div>
      )}
      {activeCategory === "automatic" && (
        <div className="bg-gray-100 border-t border-gray-200 ">
          <div className="max-w-screen-xl p-4 mx-auto text-right">
            <div className="flex items-center justify-end space-x-2">
              <IconButton
                disabled={automaticChangesDrafts.length === 0}
                type="warning"
                className="bg-white"
                Icon={FaTrashAlt}
                onClick={() => {
                  setShowRejectConfirmation(true);
                }}
              >
                {t("rejectAll")}
              </IconButton>
              {showRejectConfirmation ? (
                <ConfirmationModal
                  showConfirmation={showRejectConfirmation}
                  setShowConfirmation={setShowRejectConfirmation}
                  confirmationText={t("confirmRejectAll")}
                  type="reject"
                  confirmButtonText={t("rejectAll")}
                  applyActionCallback={() => {
                    automaticChangesDrafts.forEach((d) => {
                      const rejectDraft =
                        rejectActionMapping[d.data.__typename!];
                      rejectDraft(d.data.id);
                    });
                  }}
                />
              ) : null}
              <IconButton
                disabled={automaticChangesDrafts.length === 0}
                className="bg-white"
                Icon={FaCheck}
                onClick={() => {
                  setShowApproveConfirmation(true);
                }}
              >
                {t("approveAll")}
              </IconButton>
              {showApproveConfirmation ? (
                <ConfirmationModal
                  showConfirmation={showApproveConfirmation}
                  setShowConfirmation={setShowApproveConfirmation}
                  confirmationText={t("confirmApproveAll")}
                  type="approve"
                  confirmButtonText={t("approveAll")}
                  applyActionCallback={() => {
                    automaticChangesDrafts.forEach((d) => {
                      const approveDraft =
                        approveActionMapping[d.data.__typename!];
                      approveDraft(d.data.id);
                    });
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
      )}
      {(groupedDrafts?.length ?? 0 > 0) ? (
        <div className="flex-grow">
          {groupedDrafts.map(
            ([key, draftGroup]: [string, GroupableEntity[] | undefined]) => {
              return (
                <div key={key} className="md:pl-16 first:pt-4">
                  <DraftsGroup
                    drafts={draftGroup ?? []}
                    approveActionMapping={approveActionMapping}
                    rejectActionMapping={rejectActionMapping}
                    resetActionMapping={resetActionMapping}
                  />
                </div>
              );
            },
          )}
        </div>
      ) : (
        <div className="max-w-screen-xl px-3 mx-auto mt-8">
          Keine Änderungsanträge
        </div>
      )}
    </div>
  );
};

export default Drafts;
