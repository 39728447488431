import React from "react";
import classNames from "classnames";
import { FaEdit } from "react-icons/fa";
import { TbArrowsJoin2 } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import { languages } from "helpers/ui/multiLang";
import { Popup } from "components/popup";
import { SmallIconButton } from "components/button";
import { hyphenateText } from "helpers/hyphenate";
import {
  SkillAssoc,
  CompetenceAssoc,
  DiplomaAssoc,
  RoleAssoc,
  ReferenceRoleAssoc,
  LanguageAssoc,
  FactKeyAssoc,
} from "generated/graphql";

export type Item =
  | SkillAssoc
  | CompetenceAssoc
  | DiplomaAssoc
  | RoleAssoc
  | ReferenceRoleAssoc
  | LanguageAssoc
  | FactKeyAssoc;

// Ideally, this would just be used for the tr element, but sticky only works properly on the th element
const stickyHeaderStyle = "px-6 py-3 sticky top-0 bg-gray-50 z-10";
const headerBorder = {
  // we somehow can't use borders on th elements. This shadow looks like a border
  boxShadow:
    "inset 0 1px 0 rgb(229, 231, 235), inset 0 -1px 0 rgb(229, 231, 235)",
};

export const Table = <T extends Item>({
  hideCount = false,
  items,
  setItemToEdit,
  setItemToMerge,
}: {
  hideCount?: boolean;
  items: T[];
  setItemToEdit?: (item: T) => any;
  setItemToMerge?: (item: T) => any;
}): JSX.Element => {
  const { t } = useTranslation(["employee", "mainLanguages", "common"]);

  return (
    <table className="min-w-full table-fixed">
      <thead className="">
        <tr>
          {hideCount ? null : (
            <th
              scope="col"
              style={headerBorder}
              className={classNames(
                stickyHeaderStyle,
                "text-sm font-medium tracking-wider text-left text-black",
              )}
            >
              <Popup content={t("employee:usage")}>#</Popup>
            </th>
          )}
          {languages.map((lang) => (
            <th
              key={lang}
              scope="col"
              // I tried calc(25% - 3px), but that had no effect.
              // Setting this to 25% doesn't make sense, since it adds up to 12px more than 100%, but it seems to do what I want anyway..
              style={{ ...headerBorder, width: "25%" }}
              className={classNames(
                stickyHeaderStyle,
                "text-sm font-medium tracking-wider text-left text-black",
              )}
            >
              {t(`mainLanguages:${lang}` as any)}
            </th>
          ))}
          <th
            scope="col"
            style={{ ...headerBorder, width: "12px" }}
            className={stickyHeaderStyle}
          >
            <span className="sr-only">{t("common:edit")}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {items?.map((item, i) => {
          return (
            <tr
              key={item?.name?.id}
              className={i % 2 === 0 ? "bg-white" : "bg-gray-50"}
            >
              {hideCount ? null : (
                <td className="px-6 py-4 text-sm text-gray-900">
                  {item["employeesCount"] ?? ""}
                </td>
              )}
              {languages.map((lang) => (
                <td
                  key={lang}
                  className="px-6 py-4 text-sm text-gray-900 break-words"
                >
                  {hyphenateText(item?.name?.[lang] ?? "", lang)}
                </td>
              ))}
              <td className="px-6 py-4 space-x-2 text-sm font-medium text-right whitespace-nowrap">
                {setItemToEdit ? (
                  <SmallIconButton
                    popupContent={t("common:edit")}
                    Icon={FaEdit}
                    onClick={() => setItemToEdit(item)}
                  />
                ) : null}
                {setItemToMerge ? (
                  <SmallIconButton
                    popupContent={t("common:merge")}
                    Icon={TbArrowsJoin2}
                    onClick={() => setItemToMerge(item)}
                  />
                ) : null}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
