import { gql } from "@apollo/client";
import { multiLangString } from "./common";
import {
  employeeReferenceFragment,
  newReferenceFragment,
  parentReferenceFragment,
} from "./references";
import { postFragment } from "./post";
import { projectListFields } from "./newProject";

export const employeeFragment = gql`
  fragment Employee_fields on Employee {
    firstName
    lastName
    fullName
    activeDirectoryId
    profilePicture(size: 240)
    colorProfilePicture(size: 504)
    phone
    birthday
    email
    linkedin
    services
    domains
    isTrustedAdvisor
    isFormerEmployee
    roles {
      id
      roleId
      newText
      role {
        id
        name ${multiLangString}
      }
    }
    skills {
      id
      skillId
      newText
      skill {
        id
        name ${multiLangString}
      }
    }
    competences {
      id
      competenceId
      newText
      competence {
        id
        name ${multiLangString}
      }
    }
    aboutMe ${multiLangString}
    trainings ${multiLangString}
    languages {
      id
      level
      languageId
      language {
        id
        name ${multiLangString}
      }
    }
    diplomas {
      id
      year
      diplomaId
      newText
      diploma {
        id
        name ${multiLangString}
      }
    }
    additionalField {
        id
        title ${multiLangString}
        content ${multiLangString}
      }
    cv {
      id
      from
      to
      description ${multiLangString}
      institution ${multiLangString}
    }
    isReadyForReview
  }
`;

export const employeeRelationsFragment = gql`
  ${projectListFields}
  ${postFragment}

  fragment EmployeeRelations_fields on Employee {
    projects {
      ...ProjectList_fields
    }
    posts {
      ...Post_fields
    }
  }
`;

export const EMPLOYEE_QUERY = gql`
  ${employeeFragment}
  ${employeeRelationsFragment}
  ${newReferenceFragment}
  ${parentReferenceFragment}

  query GetEmployee($id: ID, $activeDirectoryId: String, $abacusId: ID, $employeeNumber: ID) {
    me {
      id
    }
    employee(id: $id, activeDirectoryId: $activeDirectoryId, abacusId: $abacusId, employeeNumber: $employeeNumber) {
      id
      ...Employee_fields
      ...EmployeeRelations_fields
      references(includeUnapproved: true) {
        ...NewReference_fields
        project {
          id
          isUnlisted
          parentProjects {
            id
            name ${multiLangString}
          }
        }
        parentReference {
          ...ParentReference_fields
        }
        draft {
          ...NewReference_fields
          project {
            id
            isUnlisted
            parentProjects {
              id
              name ${multiLangString}
            }
          }
          parentReference {
            ...ParentReference_fields
          }
        }
      }
      connectedFields {
        field
        isConnected
      }
      approvedBy {
        __typename
      }
      approvedEntityId
      draft {
        id
        ...Employee_fields
        ...EmployeeRelations_fields
        connectedFields {
          field
          isConnected
        }
        modifiedBy {
          __typename
        }
      }
    }
  }
`;

export const EDIT_EMPLOYEE_QUERY = gql`
  ${employeeFragment}
  ${employeeReferenceFragment}

  query GetEmployee($id: ID!) {
    employee(id: $id) {
      id
      ...Employee_fields
      references {
        ...EmployeeReference_fields
      }
      draft {
        id
        ...Employee_fields
        references {
          ...EmployeeReference_fields
        }
        connectedFields {
          field
          isConnected
        }
        modifiedBy {
          __typename
        }
      }
      connectedFields {
        field
        isConnected
      }
      approvedBy {
        __typename
      }
    }
  }
`;

export const EDIT_EMPLOYEE_MUTATION = gql`
  ${employeeFragment}

  mutation EditEmployeeMutation($id: ID!, $fields: InputEmployee!) {
    editEmployee(id: $id, fields: $fields) {
      id
      ...Employee_fields
      approvedBy {
        __typename
      }
      draft {
        id
        ...Employee_fields
        modifiedBy {
          __typename
        }
      }
    }
  }
`;

export const ALL_EMPLOYEES_QUERY = gql`
  query allEmployeesQuery {
    employees {
      id
      firstName
      lastName
      isFormerEmployee
    }
  }
`;

export const ALL_EMPLOYEES_WITH_ROLE_QUERY = gql`
  query allEmployeesWithRoleQuery {
    employees {
      id
      firstName
      lastName
      colorProfilePicture(size: 504)
      isFormerEmployee
      roles {
        id
        roleId
        role {
          id
          name ${multiLangString}
        }
      }
    }
  }
`;

export const ONEPAGER_EMPLOYEE_QUERY = gql`
  query GetEmployee($id: ID!) {
    employee(id: $id) {
      id
      phone
      email
    }
  }
`;
