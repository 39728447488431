import { gql } from "@apollo/client";

export const postFragment = gql`
  fragment Post_fields on Post {
    id
    title
    body
    image
    insertedAt
    tags {
      id
      name
    }
    author {
      id
      firstName
      lastName
      activeDirectoryId
      username: activeDirectoryId
    }
  }
`;

// QUERIES

export const POSTS_FEED_QUERY = gql`
  ${postFragment}

  query FeedPosts($offset: Int, $tags: [String]) {
    posts(offset: $offset, tags: $tags) {
      ...Post_fields
      numComments
    }
  }
`;
