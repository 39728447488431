import React from "react";
import { Link } from "wouter";
import classNames from "classnames";
import { Popup } from "components/popup";

import { IconType } from "react-icons";

export type ButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  className?: string;
  primary?: boolean;
  active?: boolean;
  group?: boolean;
  children: React.ReactNode;
};

export const Button: React.FC<ButtonProps> = ({
  onClick,
  className = "",
  children,
  disabled = false,
  primary = false,
  group = false,
}) => {
  const buttonStyles = primary
    ? "bg-red-500 text-white hover:bg-red-700 focus:ring-red-500"
    : "bg-gray-100 text-black hover:bg-gray-300 focus:ring-gray-200";

  return (
    <button
      disabled={disabled}
      type="button"
      onClick={onClick}
      className={classNames(
        "w-full inline-flex justify-center border border-transparent shadow-sm px-4 py-2 text-base focus:outline-none font-normal focus:ring-2 focus:ring-offset-2 sm:w-auto sm:text-sm",
        buttonStyles,
        className,
        group ? "first:rounded-l last:rounded-r" : "rounded",
      )}
    >
      {children}
    </button>
  );
};

export type ActionLinkProps = {
  link: string;
  label?: string;
  Icon: IconType;
  external?: boolean;
  newTab?: boolean;
  iconRight?: boolean;
  straightLeftBorder?: boolean;
  straightRightBorder?: boolean;
};

export const ActionLink = ({
  link,
  label,
  Icon,
  external = false,
  newTab = false,
  iconRight = false,
  straightLeftBorder = false,
  straightRightBorder = false,
}: ActionLinkProps): JSX.Element => {
  return external ? (
    <a
      target={newTab ? "_blank" : undefined}
      className={classNames(
        "flex items-center px-2 py-1 text-sm border border-grey-300 hover:bg-blue-500 hover:bg-opacity-5 hover:border-blue-500 hover:border-opacity-40 focus:outline-none",
        { "flex-row-reverse": iconRight },
        { "rounded-l": !straightLeftBorder },
        { "rounded-r": !straightRightBorder },
      )}
      href={link}
      rel="noreferrer"
    >
      <Icon />
      {label ? (
        <div className={classNames({ "mr-2": iconRight, "ml-2": !iconRight })}>
          {label}
        </div>
      ) : null}
    </a>
  ) : (
    <Link
      className={classNames(
        "flex items-center px-2 py-1 text-sm border border-grey-300 hover:bg-blue-500 hover:bg-opacity-5 hover:border-blue-500 hover:border-opacity-40 focus:outline-none",
        { "flex-row-reverse": iconRight },
        { "rounded-l": !straightLeftBorder },
        { "rounded-r": !straightRightBorder },
      )}
      href={link}
    >
      <Icon />
      {label ? (
        <div className={classNames({ "mr-2": iconRight, "ml-2": !iconRight })}>
          {label}
        </div>
      ) : null}
    </Link>
  );
};

export const ActionLinkRed = ({
  link,
  label,
  Icon,
  external = false,
  iconRight = false,
}) => {
  return external ? (
    <a
      className={classNames(
        "flex items-center px-2 py-1 text-sm text-red-500 border rounded border-grey-300 hover:bg-red-500 hover:bg-opacity-5 hover:border-red-500 hover:border-opacity-40 focus:outline-none",
        { "flex-row-reverse": iconRight },
      )}
      href={link}
    >
      <Icon />
      <div className="text-red-500">{label}</div>
    </a>
  ) : (
    <Link
      className={classNames(
        "flex items-center px-2 py-1 text-sm text-red-500 border rounded border-grey-300 hover:bg-red-500 hover:bg-opacity-5 hover:border-red-500 hover:border-opacity-40 focus:outline-none",
        { "flex-row-reverse": iconRight },
      )}
      href={link}
    >
      <Icon className="text-red-500" />
      <div
        className={classNames({
          "mr-2": iconRight,
          "ml-2": !iconRight,
          "text-red-500": !iconRight,
        })}
      >
        {label}
      </div>
    </Link>
  );
};

export const SingleIconButton: React.FC<any> = ({
  children,
  onClick,
  disabled = false,
  className = "",
}) => {
  return (
    <button
      type="button"
      className={classNames(
        "flex focus:outline-none items-center px-1 py-1 text-sm border border-grey-300 rounded",
        {
          "text-gray-500 hover:bg-blue-500 hover:bg-opacity-5 hover:border-blue-500 hover:border-opacity-40":
            !disabled,
          "cursor-default text-grey-200": disabled,
        },
        className,
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export const GroupedButton = ({
  children,
  className = "",
  onClick,
  selected = false,
  disabled = false,
}) => {
  return (
    <button
      type="button"
      className={classNames(
        "focus:outline-none flex items-center p-1 text-sm hover:bg-blue-500 hover:text-white transition ease-in-out",
        {
          "first:rounded-l last:rounded-r bg-blue-500 text-white hover:bg-blue-500 hover:text-white border-r border-grey-300 last:border-r-0 transition ease-in-out":
            selected && !disabled,
          "border-r border-grey-300 last:border-r-0": !selected && !disabled,
          "cursor-default border-grey-300 text-gray-300": disabled && !selected,
          "cursor-default border-grey-300 bg-gray-300 text-white":
            disabled && selected,
        },
        className,
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export const SecondaryButton = ({
  children,
  className = "",
  onClick,
  disabled = false,
}) => {
  return (
    <button
      disabled={disabled}
      type="button"
      className={classNames(
        "focus:outline-none flex items-center px-2 py-1 text-sm border border-grey-300 text-gray-500  rounded ",
        disabled
          ? "text-gray-200 cursor-default"
          : "hover:bg-blue-500 hover:bg-opacity-5 hover:border-blue-500 hover:border-opacity-40",
        className,
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export const TabButton: React.FC<ButtonProps> = ({
  onClick,
  className = "",
  children,
  primary = false,
}) => {
  const buttonStyles = primary
    ? "border-b-2 font-medium border-gray-950 text-gray-950 pb-0 rounded:none"
    : "text-gray-350 font-medium hover:text-gray-950";

  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(
        "text-black w-full inline-flex justify-center py-1 md:text-base sm:w-auto sm:text-sm focus:outline-none",
        buttonStyles,
        className,
      )}
    >
      {children}
    </button>
  );
};

export const FilterButton: React.FC<ButtonProps> = ({
  onClick,
  children,
  active,
}) => {
  return (
    <button
      className={classNames(
        "text-lg w-full justify-center md:text-lg sm:justify-start sm:w-auto sm:text-base focus:outline-none transition",
        active
          ? "text-red-500 hover:text-red-500"
          : "text-gray-900 hover:text-red-500",
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

type ButtonType =
  | "normal"
  | "search"
  | "secondary"
  | "warning"
  | "soft-warning"
  | "success";

export type IconButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  Icon: React.ComponentType<any>;
  disabled?: boolean;
  type?: ButtonType;
  straightLeftBorder?: boolean;
  straightRightBorder?: boolean;
  submit?: boolean;
  children?: React.ReactNode;
};

export type SmallIconButtonProps = IconButtonProps & {
  popupContent?: string;
};

export const SmallIconButton: React.FC<SmallIconButtonProps> = (props) => {
  const { Icon, popupContent, disabled = false, ...rest } = props;
  return (
    <Popup content={popupContent} disabled={disabled}>
      <IconButton
        disabled={disabled}
        Icon={() => (
          <Icon
            style={{
              marginTop: "0.2rem",
              marginBottom: "0.2rem",
            }}
          />
        )}
        {...rest}
      />
    </Popup>
  );
};

export const IconButton: React.FC<IconButtonProps> = ({
  Icon,
  className = "",
  onClick,
  children,
  disabled = false,
  straightLeftBorder = false,
  straightRightBorder = false,
  type = "normal",
  submit,
}) => {
  const button = (
    <button
      type={submit ? "submit" : "button"}
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
      className={classNames(
        "flex items-center px-2 py-1 p-1  text-sm border  border-grey-300 whitespace-pre focus:outline-none",
        straightLeftBorder
          ? straightRightBorder
            ? ""
            : "rounded-r"
          : "rounded",
        disabled
          ? "text-gray-200 cursor-default"
          : "hover:bg-opacity-5 hover:border-opacity-40",
        className,
        disabled
          ? ""
          : {
              "text-blue-500 hover:bg-blue-500 hover:border-blue-500 transition":
                type === "normal",
              "text-blue-500 hover:border-blue-500 border-none transition":
                type === "search",
              "text-gray-500 hover:bg-gray-300 hover:border-gray-500 transition":
                type === "secondary",
              "text-red-500 hover:bg-red-100 hover:border-red-500 transition":
                type === "warning",
              "text-yellow-600 hover:bg-yellow-100 hover:border-yellow-600 transition":
                type === "soft-warning",
            },
      )}
    >
      <Icon aria-hidden="true" />
      {children ? <span className="ml-2">{children}</span> : null}
    </button>
  );
  return button;
};
