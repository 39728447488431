import { gql } from "@apollo/client";

// QUERIES

export const SHARED_FILES_QUERY = gql`
  query GetSharedFiles {
    sharedFiles {
      id: token
      downloadCount
      expiresAt
      path
      tbfLink
      token
      externalLink
      insertedAt
    }
  }
`;

// MUATIONS

export const CREATE_SHARED_FILE_MUTATION = gql`
  mutation CreateSharedFile($path: String!, $expiresAt: DateTime) {
    createSharedFile(expiresAt: $expiresAt, path: $path) {
      id: token
      token
      downloadCount
      expiresAt
      tbfLink
      path
      externalLink
      insertedAt
    }
  }
`;

export const DELETE_SHARED_FILE_MUTATION = gql`
  mutation DeleteSharedFile($token: String!) {
    deleteSharedFile(token: $token) {
      id: token
    }
  }
`;
