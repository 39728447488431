import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React from "react";
import { Link } from "wouter";
import removeMd from "remove-markdown";
import { useTranslation } from "react-i18next";
import { getApiUrl } from "helpers/apiUrl";

dayjs.extend(relativeTime);

import { Post } from "../types";
import { FaRegComments } from "react-icons/fa";

// Helper Functions

const truncateString = (str: string) => {
  const text = removeMd(str);
  const len = 300;
  if (text.length <= len + 3) {
    return text;
  } else {
    return text.substring(0, len) + "...";
  }
};

// COMPONENTS

// MAIN COMPONENT

const Excerpt = ({ post }: { post: Post }) => {
  const { t } = useTranslation("posts");
  return (
    <div className="w-full mb-12 border-none shadow-none">
      <div className="flex flex-col justify-center w-full sm:flex-row">
        <div className="flex justify-center pb-4 sm:pb-none sm:w-1/3 sm:pr-8">
          {post.image ? (
            <Link href={`/posts/${post.id}`}>
              <img
                className="object-cover w-full"
                src={`${getApiUrl()}${post.image}`}
              />
            </Link>
          ) : null}
        </div>
        <div className="flex flex-col w-full sm:w-2/3">
          <div className="justify-center mb-3 sm:justify-start">
            {post.tags.length > 0 ? (
              <div className="flex flex-row flex-wrap justify-center mb-3 sm:justify-start">
                {post.tags.map((tag: any) => (
                  <label
                    className="flex items-center px-2 mb-2 ml-0 mr-3 text-xs text-blue-500 bg-blue-500 border-none rounded sm:text-sm bg-opacity-10"
                    key={tag.name}
                  >
                    {tag.name}
                  </label>
                ))}
              </div>
            ) : null}
            <h3>
              <Link
                className="inline-block text-xl font-medium text-center text-red-500 sm:text-left"
                href={`/posts/${post.id}`}
              >
                {post.title}
              </Link>
            </h3>
          </div>
          <p>
            <Link
              className="inline-block pb-3 text-base leading-5 text-gray-500 hover:text-blue-500"
              href={`/posts/${post.id}`}
            >
              {truncateString(post.body)}
            </Link>
          </p>
          <div className="flex flex-row justify-between w-full">
            {post.author ? (
              <div className="text-gray-500">
                <Link
                  className="text-blue-500 hover:text-gray-500"
                  href={`/user/${post.author.username}`}
                >
                  {post.author.firstName} {post.author.lastName}
                </Link>{" "}
                {dayjs(post.insertedAt).fromNow()}
              </div>
            ) : (
              <div className="text-gray-500">
                <span>{t("unknownUser")}</span>{" "}
                {dayjs(post.insertedAt).fromNow()}
              </div>
            )}
            {post.numComments && post.numComments > 0 ? (
              <Link
                className="flex flex-row justify-end hover:text-gray-500"
                href={`/posts/${post.id}`}
              >
                <FaRegComments className="mr-1 text-gray-500" />
                {"  " + t("comments", { count: post.numComments })}
              </Link>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Excerpt;
