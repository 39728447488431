import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/button";

// @ts-ignore
import tbfLogo from "assets/images/tbf-logo.svg";
// @ts-ignore
import robot from "assets/images/robot.png";

// MAIN COMPONENT

const ErrorPage = ({ error }: any) => {
  const { t } = useTranslation("error");
  const [showDetails, setShowDetails] = useState(false);

  // we also print to console for easier debugging
  console.error(error);

  return (
    <div>
      <div className="m-0">
        <div className="max-w-5xl p-8 mx-auto">
          <a href="/posts">
            <img src={tbfLogo} className="w-32 h-full" alt="TBF + Partner AG" />
          </a>
        </div>
        <div className="text-center bg-blue-500">
          <h1 className="p-8 mx-auto text-3xl font-normal text-white">
            {t("title")}
          </h1>
        </div>
        <div className="flex max-w-5xl px-24 mx-auto mt-8">
          <img
            className="w-24 h-full my-0 ml-8 mr-4 display-block"
            src={robot}
            alt="TBF Error"
          />
          <div>
            <p className="flex-1 max-w-lg mx-4 my-2 text-sm">
              {t("description")}{" "}
              <a href="https://ticketing.tbf.ch">ticketing.tbf.ch</a>
            </p>
          </div>
        </div>
        <div className="max-w-4xl mx-auto space-y-4 text-center">
          <Button
            primary
            onClick={() => {
              window.location.href = "/";
            }}
          >
            {t("homeLink")}
          </Button>
          <div>
            {showDetails ? (
              <>
                <Button
                  onClick={() => {
                    setShowDetails(false);
                  }}
                >
                  {t("hideInfo")}
                </Button>
                <div className="mt-8 font-mono">
                  <div>
                    Error:{" "}
                    {JSON.stringify(
                      error,
                      Object.getOwnPropertyNames(error ?? {}),
                      2,
                    )}
                  </div>
                </div>
              </>
            ) : (
              <Button
                onClick={() => {
                  setShowDetails(true);
                }}
              >
                {t("showInfo")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
