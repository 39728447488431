import { useQuery, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Popup } from "components/popup";
import toast from "react-hot-toast";

import {
  SHARED_FILES_QUERY,
  CREATE_SHARED_FILE_MUTATION,
  DELETE_SHARED_FILE_MUTATION,
} from "queries/fileShare";
import Layout from "layout/layout";

import { copyToClipboard } from "helpers/clipboard";

import {
  FaFileAlt,
  FaFolderOpen,
  FaLink,
  FaPlus,
  FaQuestionCircle,
  FaRegClock,
  FaTrashAlt,
} from "react-icons/fa";
import { Modal } from "components/modal";
import { ActionLink, IconButton } from "components/button";
import { IoClose } from "react-icons/io5";
import { FiSearch } from "react-icons/fi";
import { SharedFile } from "generated/graphql";

// MAIN COMPONENT

const FileShare = (): JSX.Element => {
  const { t } = useTranslation(["fileShare", "common"]);
  const [error, setError] = useState<any>(null);
  const [createdSharedFile, setCreatedSharedFile] = useState<SharedFile | null>(
    null,
  );

  const { data } = useQuery(SHARED_FILES_QUERY);
  const sharedFiles = [...(data?.sharedFiles ?? [])] as SharedFile[];
  const neverExpiredFiles = sharedFiles
    .filter((file) => !file.expiresAt)
    .sort((a, b) => dateSorter(b.insertedAt, a.insertedAt));
  const willExpireFiles = sharedFiles
    .filter((file) => dayjs(file.expiresAt).isAfter(Date.now()))
    .sort((a, b) => dateSorter(a.expiresAt, b.expiresAt));
  const [createSharedFile] = useMutation(CREATE_SHARED_FILE_MUTATION);
  const [deleteSharedFile] = useMutation(DELETE_SHARED_FILE_MUTATION);

  const performDeleteSharedFile = (token: string) => {
    deleteSharedFile({
      variables: { token },
      refetchQueries: ["GetSharedFiles"],
    });
  };

  const onSubmit = ({ docPath, expiryKey }) => {
    const expiryDate = {
      d: dayjs().add(1, "day"),
      w: dayjs().add(1, "week"),
      m: dayjs().add(1, "month"),
      f: null,
    }[expiryKey];
    const expiresAt = expiryDate ? expiryDate.toISOString() : null;

    createSharedFile({
      variables: { path: docPath, expiresAt },
      refetchQueries: ["GetSharedFiles"],
    })
      .then((result: any) => {
        setError(null);
        setCreatedSharedFile(result.data.createSharedFile);
      })
      .catch(() => {
        setError("err");
      });
  };

  const FileList = ({
    title,
    files,
  }: {
    title?: string;
    files: SharedFile[];
  }) =>
    files.length > 0 && (
      <>
        {title && (
          <h3 className="text-lg font-medium text-gray-350 mt-8 mb-4">
            {title}
          </h3>
        )}
        <div className="divide-solid divide-y">
          {files.map((link) => (
            <FileEntry
              key={link.token}
              link={link}
              performDeleteSharedFile={performDeleteSharedFile}
              setCreatedSharedFile={setCreatedSharedFile}
            />
          ))}
        </div>
      </>
    );

  return (
    <Layout title={t("fileShare:title")} noPadding>
      <div>
        <div className="px-3 mx-auto text-gray-800 max-w-screen-tbf">
          <CreateLinkForm onSubmit={onSubmit} error={error} />

          <div className="pb-12">
            <CreateSharedFileModal
              isOpen={!!createdSharedFile}
              createdSharedFile={createdSharedFile}
              setCreatedSharedFile={setCreatedSharedFile}
            />

            <h2 className="mt-8 mb-3 text-2xl font-medium text-red-500">
              {t("fileShare:yourLinks")}
            </h2>

            <FileList files={willExpireFiles} />
            <FileList
              title={t("fileShare:neverExpires")}
              files={neverExpiredFiles}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

// HELPER COMPONENTS

const FileEntry = ({
  link,
  setCreatedSharedFile,
  performDeleteSharedFile,
}: {
  link: SharedFile;
  setCreatedSharedFile: (file: SharedFile) => void;
  performDeleteSharedFile: (token: string) => void;
}) => {
  const { t } = useTranslation(["fileShare", "common"]);
  const [fileLocation, fileName] = splitPath(link.path ?? "");
  return (
    <div className="flex flex-row justify-between p-2">
      <div className="flex flex-row items-center justify-between">
        {link?.externalLink ? (
          <FaFileAlt className="text-2xl w-14" />
        ) : (
          <FaFolderOpen className="text-2xl w-14" />
        )}
        <div className="max-w-xl">
          <div className="text-lg">
            {fileName}
            {<ExpiresAt expiresAt={link.expiresAt} />}
          </div>
          <p className="text-sm text-gray-500">{fileLocation}</p>
        </div>
      </div>
      <div className="flex flex-row items-center gap-x-2">
        {link?.externalLink ? (
          <div>
            <IconButton
              Icon={FaLink}
              onClick={() => {
                copyToClipboard(
                  `${window.location.origin}${link.externalLink}`,
                );
                toast.success(t("fileShare:copySuccess"));
              }}
            >
              {t("fileShare:copyExternalLink")}
            </IconButton>
          </div>
        ) : (
          <a
            className="flex items-center px-2 py-1 text-sm border rounded-sm border-grey-300 hover:bg-blue-500 hover:bg-opacity-5 hover:border-blue-500 hover:border-opacity-40 focus:outline-none"
            target="_blank"
            rel="noopener noreferrer"
            href={fullUrl(link.tbfLink ?? "")}
          >
            {t("fileShare:fileModalOpenLocation")}
          </a>
        )}
        <div>
          <IconButton
            Icon={FiSearch}
            onClick={() => {
              setCreatedSharedFile(link);
            }}
          >
            {t("fileShare:openLinkDetails")}
          </IconButton>
        </div>
        <div>
          <IconButton
            type={"soft-warning"}
            Icon={FaTrashAlt}
            onClick={() => {
              performDeleteSharedFile(link.token ?? "");
            }}
          >
            {t("common:delete")}
          </IconButton>
        </div>
      </div>
    </div>
  );
};

const CreateSharedFileModal = ({
  isOpen,
  createdSharedFile,
  setCreatedSharedFile,
}) => {
  const { t } = useTranslation(["fileShare", "common"]);
  const [fileLocation, fileName] = splitPath(createdSharedFile?.path ?? "");
  return (
    <Modal
      open={isOpen}
      title={
        <div className="flex items-start justify-between px-6 py-8 space-x-4">
          <h2 className="text-2xl font-medium text-red-500 ">
            {t("fileShare:fileModalTitle") + " " + fileName}
          </h2>
        </div>
      }
      close={() => {
        setCreatedSharedFile(null);
      }}
      actions={
        <div className="flex flex-row-reverse justify-between w-full">
          <div className="flex items-center px-6 space-x-2">
            <IconButton
              type="secondary"
              Icon={IoClose}
              onClick={() => setCreatedSharedFile(null)}
            >
              {t("common:close")}
            </IconButton>
          </div>
        </div>
      }
    >
      <div className="w-full border-b border-gray-300">
        <div className="flex flex-row items-center justify-between p-8">
          <div className="flex flex-row items-center gap-x-5">
            <FaFileAlt className="text-3xl" />
            <div className="">
              <div className="text-xl">{fileName}</div>
              <p className="">{fileLocation}</p>
            </div>
          </div>

          <div>
            <ActionLink
              Icon={FaFolderOpen}
              label={t("fileShare:fileModalOpenLocation")}
              external
              link={fullUrl(createdSharedFile?.tbfLink)}
            />
          </div>
        </div>
      </div>
      <div className="w-full p-8 border-b border-gray-300">
        <div className="mb-3 text-xl">
          {t("fileShare:fileModalExternalLinkTitle")}
        </div>
        {createdSharedFile?.externalLink ? (
          <>
            <div className="flex flex-row">
              <input
                className="w-full px-3 py-2 text-lg border border-r-0 border-gray-300 rounded-l focus:outline-none"
                readOnly={true}
                type="text"
                value={fullUrl(createdSharedFile.externalLink)}
              />
              <IconButton
                Icon={FaLink}
                className="rounded-l-none rounded-r"
                onClick={() => {
                  copyToClipboard(fullUrl(createdSharedFile.externalLink));
                  toast.success(t("fileShare:copySuccess"));
                }}
              >
                <p>{t("fileShare:fileModalCopyLink")}</p>
              </IconButton>
            </div>
            <div className="flex flex-row items-center pt-3 gap-x-2 text-gray-350">
              <FaQuestionCircle />
              <p>{t("fileShare:fileModalExternalLinkDesc")}</p>
            </div>
          </>
        ) : (
          <>{t("fileShare:fileModalNoShareFolders")}</>
        )}
      </div>

      <div className="w-full p-8">
        <div className="mb-3 text-xl">
          {t("fileShare:fileModalInternalLinkTitle")}
        </div>
        <div className="flex flex-row ">
          <input
            className="w-full px-3 py-2 text-lg border border-r-0 border-gray-300 rounded-l focus:outline-none"
            readOnly={true}
            type="text"
            value={fullUrl(createdSharedFile?.tbfLink)}
          />
          <IconButton
            className="rounded-l-none rounded-r"
            Icon={FaLink}
            submit
            onClick={() => {
              if (createdSharedFile === null) {
                return;
              }
              copyToClipboard(fullUrl(createdSharedFile.tbfLink));
              toast.success(t("fileShare:copySuccess"));
            }}
          >
            <p>{t("fileShare:fileModalCopyLink")}</p>
          </IconButton>
        </div>
        <div className="flex flex-row items-center pt-3 gap-x-2 text-gray-350">
          <FaQuestionCircle />
          <p> {t("fileShare:fileModalInternalLinkDesc")}</p>
        </div>
      </div>
    </Modal>
  );
};

export const FilePathInput = ({
  path,
  setPath,
  setError,
}: any): JSX.Element => {
  const { t } = useTranslation("fileShare");
  return (
    <div className="flex flex-col mb-3">
      <div className="flex flex-row items-center mb-3 gap-x-2">
        <label>{t("filePath")}</label>
        <Popup
          content={
            <div>
              <p>{t("examplePath")}:</p>
              <p className="pt-2 pb-1">T:\42000 - 42499\42424\Dokument.pdf</p>
              <p>
                \\ZHTBFFS0001\Externe Auftraege\22000 - 22999\22900\test.pdf
              </p>
            </div>
          }
        >
          <FaQuestionCircle />
        </Popup>
      </div>

      <input
        placeholder={t("pathInputPlaceholder")}
        value={path}
        className="w-full px-3 py-3 text-lg border border-gray-300 rounded"
        onChange={(e) => {
          setPath(e.target.value);
          if (setError) {
            setError(null);
          }
        }}
      />
    </div>
  );
};

const CreateLinkForm = ({ onSubmit, error }: any) => {
  const { t } = useTranslation("fileShare");

  const [expiryKey, setExpiryKey] = useState("w");
  const [docPath, setDocPath] = useState("");

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit({ expiryKey, docPath });
      }}
    >
      <FilePathInput path={docPath} setPath={setDocPath} />

      {error ? (
        <div className="px-5 py-3 mt-4 bg-red-200 border border-red-300 rounded bg-opacity-5">
          <h2 className="text-xl font-medium">{t("errorHeader")}</h2>
          <p>{t("error")}</p>
        </div>
      ) : null}

      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row gap-x-5">
          <label>{t("validFor")}</label>
          <div className="flex flex-row items-center gap-x-2">
            <input
              className="cursor-pointer"
              id="d"
              type="radio"
              value="d"
              checked={expiryKey === "d"}
              onChange={() => setExpiryKey("d")}
            />
            <label htmlFor="d">{t("d")}</label>
          </div>

          <div className="flex flex-row items-center gap-x-2">
            <input
              className="cursor-pointer"
              id="w"
              type="radio"
              value="w"
              checked={expiryKey === "w"}
              onChange={() => setExpiryKey("w")}
            />
            <label htmlFor="w">{t("w")}</label>
          </div>

          <div className="flex flex-row items-center gap-x-2">
            <input
              className="cursor-pointer"
              id="m"
              type="radio"
              value="m"
              checked={expiryKey === "m"}
              onChange={() => setExpiryKey("m")}
            />
            <label htmlFor="m">{t("m")}</label>
          </div>

          <div className="flex flex-row items-center gap-x-2">
            <input
              className="cursor-pointer"
              id="f"
              type="radio"
              value="f"
              checked={expiryKey === "f"}
              onChange={() => setExpiryKey("f")}
            />
            <label htmlFor="f">{t("f")}</label>
          </div>
        </div>
        <IconButton Icon={FaPlus} submit className="my-4">
          {t("share")}
        </IconButton>
      </div>
    </form>
  );
};

const ExpiresAt = ({ expiresAt }) => {
  return expiresAt ? (
    <span
      className="mx-4 text-gray-500 inline-flex items-center text-sm"
      title={expiresAt}
    >
      <FaRegClock className="mr-1" />
      {dayjs(expiresAt).fromNow(true)}
    </span>
  ) : null;
};

const fullUrl = (link: string) =>
  link ? `${window.location.origin}${link}` : "";

const splitPath = (path: string) => {
  let name = "";
  let location = "";
  for (const pathSplitChar of ["\\", "/"]) {
    if (path.includes(pathSplitChar)) {
      const s = path.split(pathSplitChar);
      name = s.slice(-1)[0];
      location = s.slice(0, s.length - 1).join(pathSplitChar);
    }
  }

  return [location, name];
};

const dateSorter = (a?: string | null, b?: string | null) =>
  new Date(a ?? "3000-01-01T00:00:00").getTime() -
  new Date(b ?? "3000-01-01T00:00:00").getTime();

export default FileShare;
